<template>
  <div class="app-members-edit">
    <Module-Header :title="`Member Details (Member ID: ${info.id})`" :leading="true">
      <el-button class="search" @click="toTransaction"><img src="@/assets/images/operation.png"/>Transaction</el-button>
      <el-button class="delete" @click="del"><img src="@/assets/images/delete-01.png"/>Delete</el-button>
    </Module-Header>

    <div class="form">
      <div class="title">Personal Information</div>
      <div class="form-single">
        <div class="label">Contact No.</div>
        <div class="value">{{ info.contactNumber }}</div>
      </div>
      <div class="form-single">
        <div class="label">Email</div>
        <div class="value">{{ info.email }}</div>
      </div>
      <div class="form-single">
        <div class="label">Date of Joining</div>
        <div class="value">{{ info.joinAt }}</div>
      </div>
    </div>

    <div class="form">
      <div class="title">Stamps</div>
      <div class="form-single">
        <div class="label">Total</div>
        <div class="value">{{ info.allStamps }}</div>
      </div>
    </div>

    <div class="table-box">
      <el-table :data="tableData" ref="table" style="width:100%;">
        <el-table-column v-for="(its, idx) in tableCate" :key="idx"
          :prop="its.prop" :label="its.label" :width="its.width" sortable> </el-table-column>
      </el-table>
      <Pagination
        :total="total"
        :autoScroll='false'
        :page.sync="listQ._start"
        :limit.sync="listQ._limit"
        layout="prev, pager, next"
        :pager-count="count"
        @pagination="getData"
      />
    </div>
  </div>
</template>

<script>
import { getMembersDetail, delMembers } from '@/api/appMembers'

export default {
  data() {
    return {
      info: {},

      tableCate: [
        {label: 'Eatery Name', prop: 'eateryName', width: '270'},
        {label: 'Belance', prop: 'stampBalance', width: ''},
      ],
      tableData: [],
      total: 0,
      count: 5,
      listQ:{
        _start: 1,
        _limit: 20,
      },
    }
  }, 
  methods: {
    toTransaction() {
      let keysWord = this.info.id
      this.$router.push({path: '/stampTransactions', query: {keysWord}})
    },
    del() {
      this.$popUp.config({
        content: 'This action cannot be undone.',
        confirm: {
          e: () => {
            let id = this.$route.params.id
            delMembers(id).finally(() => {
              this.$popUp.removePopup()
              this.$router.go(-1)
            })
          }
        },
      })
    },
    getData() {
      let id = this.$route.params.id
      getMembersDetail(id).then(v => {
        v.data.joinAt = new Date(v.data.joinAt).format('yyyy/MM/dd hh:mm:ss')
        this.info = v.data
        this.info.allStamps = 0

        this.info.stampTransactions.filter(item => {
          if(item.stampOperationType == 0) {
            this.info.allStamps += item.stamps
            item.stampBalance = '+' + item.stamps
          } else item.stampBalance = '-' + item.stamps
          item.eateryName = item.eateryName.zhHant
        })
        this.tableData = this.info.stampTransactions
      })
    },
  },
  mounted() {
    this.getData()

  }
}
</script>

<style scoped lang="scss">
.form {
  margin-top: 1.875rem;
  width: 100%;
  .title {
    color: #1B7379;
    font-size: 1.5rem;
    font-weight: 700;
  }

  .form-single {
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1.25rem;
    color: #000;
    font-size: 1.125rem;

    .label {
      width: 12.5rem;
      font-weight: 700;
    }
  }

}
.table-box{
  width:100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .editor {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      cursor: pointer;
    }
  }
}
</style>